<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="close" v-on:input="close" max-width="950px">
      <v-card>
        <v-form v-on:submit.prevent="addItems">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <div class="row">
              <div class="col-md-12 pb-0">
                <v-chip class="mb-0 mt-4" v-if="selected.length == 1">Seleccionaste {{ selected.length }} tarifa</v-chip>
                <v-chip v-else class="mb-0 mt-4">Seleccionaste {{ selected.length }} tarifas</v-chip>
              </div>

              <div class="col-md-12 mt-3">
                <v-divider></v-divider>
                <p class="caption">Selecciona los transportes que deseas agregar a las tarifas seleccionadas</p>
                <v-combobox
                  v-model="item.transportes"
                  chips
                  small-chips
                  data-vv-as="transportes"
                  name="transportes"
                  item-text="nameTransport"
                  item-value="id"
                  multiple
                  :items="transportes"
                  label="Transportes"
                  autocomplete="off"
                  v-validate="'required'"
                ></v-combobox>
                <span class="red--text">{{ errors.first("transporte") }}</span>
              </div>
            </div>

            <v-card class="mb-12 mt-3" elevation="0" style="min-height:350px">
              <p class="caption">Agrega adicionales a las tarifas seleccionadas</p>

              <v-btn small class="mt-3 mb-3" color="primary" @click="addAdicionalModal">
                Agregar Adicional
              </v-btn>
              <v-data-table :headers="headers" :items="item.adicionales">
                <template v-slot:item="props">
                  <tr>
                    <td>
                      {{ (pagination.page - 1) * pagination.itemsPerPage + props.index + 1 }}
                    </td>
                    <td>{{ props.item.adicional.nombre }}</td>
                    <td>{{ formatUtils.formatPrice(props.item.cost) }}</td>
                    <td class="justify-content-center px-0">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)">
                            edit
                          </v-icon>
                        </template>
                        <span>{{ $t("Editar") }}</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" small color="red" @click="deleteItem(props.item)">
                            delete
                          </v-icon>
                        </template>
                        <span>Eliminar</span>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="close">{{ $t("Cerrar") }}</v-btn>
            <v-btn color="success" text type="submit">Agregar</v-btn>
          </v-card-actions>
          <ModalAdicional :itemAdicional="itemAdicional" :dialog="dialog_adicional" @close="dialog_adicional = false" @action="addAdicional" />
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { transportService } from "@/libs/ws/transportService";
import { formatUtils } from "@/libs/utils/formatUtils";

import ModalAdicional from "./ModalAdicional.vue";
export default {
  props: {
    modal_title: String,
    dialog: Boolean,
    selected: Array,
    service: Object,
  },
  components: {
    ModalAdicional,
  },
  data() {
    return {
      formatUtils: formatUtils,
      dialog_adicional: false,
      transportes: [],
      headers: [
        { text: "Nro", value: "id", sortable: false },
        { text: "Adicional", value: "adicional", sortable: true },
        { text: "Importe", value: "costo", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      step: 1,
      pagination: {
        page: 1,
        itemsPerPage: 5,
      },
      item: {
        transportes: null,
        adicionales: [],
      },
      itemAdicional: null,
      isActive: false,
    };
  },
  watch: {
    dialog() {},
  },
  mounted() {
    this.loadTransporte();
  },
  methods: {
    deleteItem(item) {
      this.item.adicionales.splice(this.item.adicionales.indexOf(item), 1);
    },
    editItem(item) {
      this.itemAdicional = item;
      this.dialog_adicional = true;
    },
    addAdicionalModal() {
      this.itemAdicional = null;
      this.dialog_adicional = true;
    },
    addAdicional(item) {
      console.log("item", item);
      if (this.itemAdicional != null) {
        this.item.adicionales[this.item.adicionales.indexOf(this.itemAdicional)] = item;
      } else {
        //busco si existe un adicional con el mismo id
        let index = this.item.adicionales.findIndex((x) => x.adicional.id == item.adicional.id);
        if (index >= 0) {
          this.$toast.error("Ya existe el adicional en el listado, no puede agregarlo nuevamente", "Error");
          return;
        } else {
          this.item.adicionales.push(item);
        }
      }

      this.dialog_adicional = false;
    },
    loadTransporte() {
      transportService
        .getAll()
        .then((response) => {
          this.transportes = response.transports;
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },
    close() {
      this.$emit("updateDialog");
      this.$emit("loadList");
      this.item.adicionales = [];
      this.item.transportes = null;
    },
    addItems() {
      const body = {
        Tarifas: this.selected.map((tarifa) => tarifa.id),
        Transportes: this.item.transportes ? this.item.transportes.map((t) => t.id) : [],
        Aditionales: this.item.adicionales.map((ad) => ({
          IdAditional: ad.adicional.id,
          Cost: parseFloat(ad.cost),
        })),
      };

      this.service
        .updateRateMasive(body)
        .then((response) => {
          this.close();
          this.$toast.success("Se actualizaron las tarifas y transportes.", "OK");
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.$toast.error("Ocurrió un error", "Error");
        });
    },
  },
};
</script>
