<template>
  <v-dialog v-if="item != null" v-model="dialog" @click:outside="$emit('close', item)" max-width="650">
    <v-card>
      <v-card-title class="headline">Subir Imagenes</v-card-title>

      <v-card-text>
        <div class="row mt-4 pb-3"></div>
        <p class="mb-0 fs-16">Seleccione las imagenes que desea agregar al viaje</p>
        <v-file-input v-model="item.imagesUpload" counter multiple truncate-length="15"></v-file-input>

        <hr v-if="item.images.length > 0" />
        <p v-if="item.images.length > 0" class="mb-3" style="font-size:18px"><b>Imagenes subidas</b></p>

        <div class="row">
          <div class="col-md-4 text-center" v-for="(item, image) in item.images" :key="image.id">
            <a :href="item.image" target="_blank"><img :src="item.image" style="height: 150px;width: 100%;object-fit: cover;"/></a>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="red" class="" style="position:absolute; top: 10px;right: 10px;" @click="deleteImage(item)">
                  delete
                </v-icon>
              </template>
              <span>{{ $t("Eliminar") }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red darken-1" text @click="$emit('close', item)">
          Cancelar
        </v-btn>

        <v-btn color="green darken-1" :loading="loading" text @click="$emit('uploadImages', item)">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="dialog_delete = false" @deleteItemConfirm="confirmDelete" />
  </v-dialog>
</template>

<script>
import { rutasService } from "@/libs/ws/rutasService";
import DialogDelete from "@/components/DialogDelete.vue";

export default {
  components: {
    DialogDelete,
  },
  props: {
    dialog: Boolean,
    loading: Boolean,
    item: Object,
    uploadImages: Function,
    close: Function,
    reloadList: Function,
  },
  data() {
    return {
      dialog_delete: false,
      delete_item: null,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    deleteImage(item) {
      this.delete_item = item;
      this.dialog_delete = true;
    },
    confirmDelete() {
      var param = {
        IdImage: this.delete_item.idImage,
        IdTrip: this.item.ruta,
      };
      rutasService.removeImage(param).then((res) => {
        if (res.status == 200) {
          this.$toast.success("Imagen eliminada correctamente", "Ok");
          this.item.images = this.item.images.filter((item) => item.id !== this.delete_item.id);
          this.dialog_delete = false;
          this.$emit("reloadList");
        } else {
          this.$toast.error("Error al eliminar la imagen", "Error");
          this.dialog_delete = false;
        }
      });
    },
  },
};
</script>
