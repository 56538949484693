import { akeronService } from "./akeronService";

const section = "/Report/ListReport";

function getReport(item) {
  const param = {
    type: item.type,
    numType: item.numType,
    fromDate: item.fecha_inicio,
    ToDate: item.fecha_fin,
  };

  if (item.tipo == "Facturacion") {
    const param = {
      Clients: item.clients,
      fromDate: item.fecha_inicio,
      ToDate: item.fecha_fin,
    };
    return akeronService.make_post_request("Report/ListReportFacturacion", param);
  } else {
    const param = {
      type: item.type,
      numType: item.numType,
      fromDate: item.fecha_inicio,
      ToDate: item.fecha_fin,
    };
    return akeronService.make_post_request(section, param);
  }
}

export const reportService = {
  getReport,
};
