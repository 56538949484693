<template>
  <div>
    <v-dialog v-model="show" max-width="400px" @keydown.esc="$emit('close')" @click:outside="$emit('close')">
      <v-card>
        <v-form v-on:submit.prevent="print">
          <v-card-title style="margin-bottom:0px !important">
            <span class="headline">Confirmar</span>
          </v-card-title>

          <v-card-text>
            <p class="fs-16">
              {{ mensaje }}
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('close')">No</v-btn>
            <v-btn color="success" text @click="$emit('action')" :loading="loading">Si</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    close: Function,
    action: Function,
    mensaje: String,
    loading: {
      type: Boolean,
      default: false,
    },
  },
  name: "DialogConfirm",
  mixins: [],
  created() {},
  data() {
    return {
      confirm: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>
